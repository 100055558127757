<template>
  <div>
    <b-row class="no-gutters">
      <b-col>
        <h1 class="mr-sm-4 header-tablepage">RECEIPT MONITOR DETAIL</h1>
      </b-col>
    </b-row>
    <div class="title-tabs mt-3">Upload Receipt Image</div>
    <b-container class="no-gutters bg-white pt-3">
      <div class="py-3">
        <b-row>
          <b-col cols="12">
            <div class="input-action">
              <InputText
                textFloat="Customer"
                :value="form.displayValue"
                placeholder="Please Select Customer"
                type="text"
                isRequired
                :v="$v.form.user_guid"
                :isValidate="$v.form.user_guid.$error"
                readonly
                @inputClick="handleCustomer(index)"
              />
              <div class="input-action-icon">
                <b-icon
                  stacked
                  icon="search"
                  scale="0.5"
                  variant="grey"
                  @click="handleCustomer(index)"
                ></b-icon>
              </div>
            </div>
          </b-col>

          <div class="thumbnailGroup p-3 w-100">
            <MultipleImages
              textFloat="Upload Receipt"
              @updateImageList="updateImages"
              :ratio="false"
              :dataList="form.image_url"
              v-on:deleteImagesList="deleteImages"
              :v="$v.form.image_url.$error"
            />
            <div v-if="$v.form.image_url.$error" class="validate-text">
              <span class="text-error"> Please Upload Receipt Image. </span>
            </div>
          </div>
        </b-row>
      </div>
    </b-container>
    <FooterAction
      :routePath="`/setting/receipt/monitor/${id}`"
      @submit="submit"
    />
    <ModalCustomerList ref="customerModal" @select="handleSelectedCustomer" />
  </div>
</template>
<script>
import { required, minValue } from "vuelidate/lib/validators";

import InputText from "@/components/inputs/InputText.vue";
import ModalCustomerList from "@/components/ticket/activity/ModalCustomerList";
export default {
  components: {
    InputText,

    ModalCustomerList,
  },
  validations() {
    return {
      form: {
        user_guid: { required },
        image_url: { required },
      },
    };
  },
  data() {
    return {
      id: this.$route.params.receipt_id,
      isCustomer: false,
      form: {
        user_guid: "",
        image_url: [],
        displayValue: "",
      },
    };
  },
  methods: {
    updateImages(val) {
      this.form.image_url.push(val);
    },
    deleteImages() {},
    handleCustomer() {
      this.$refs.customerModal.show();
    },
    async submit() {
      this.$v.form.$touch();
      if (this.$v.form.$error) return true;

      this.$bus.$emit("showLoading");

      let imageErrorList = [];
      let isSuccess = 0;
      let isError = 0;
      let result = null;
      let uploadedCount = this.form.image_url.length;
      for (const image of this.form.image_url) {
        let body = {
          receipt_scanner_id: this.id,
          image_url: image.image_url,
          user_guid: this.form.user_guid,
        };
        const res = await this.axios.post("/ReceiptScanner/submit", body);
        result = res.data.detail;
        if (res.data.result == 1) {
          isSuccess += 1;
        } else {
          isError += 1;
          imageErrorList.push(image);
        }
      }
      this.$bus.$emit("hideLoading");

      if (isError > 0) {
        if (result.isMaxError) {
          this.errorAlert(
            "เกิดข้อผิดพลาด !",
            `เนื่องจากมมีการส่งใบเสร็จที่ ไม่พบ / รูปแบบไม่ถูกต้อง ${result.errorCount}/${this.form.error_limit} ต่อวัน`
          );
        } else if (result.isMaxUpload) {
          this.errorAlert(
            "เกิดข้อผิดพลาด !",
            `วันนี้คุณได้ส่งตรวจสอบครบแล้ว ${result.uploadedCount}/${this.form.user_limit} ต่อวัน`
          );
        }
        this.errorAlert("เกิดข้อผิดพลาดบางประการ");
      } else {
        this.successAlert(
          `อัพโหลดใบเสร็จสำเร็จ (${isSuccess}/${uploadedCount}ใบ)`
        );
        this.form = {
          id: this.form.id,
          user_guid: "",
          image_url: [],
        };
      }
      this.$v.form.$reset();
    },
    handleSelectedCustomer(customer) {
      this.form.displayValue = [customer.name, customer.telephone]
        .filter((el) => el)
        .join(", ");
      this.form.user_guid = customer.user_guid;
      this.$forceUpdate();
    },
  },
};
</script>
<style lang="scss">
.input-action {
  position: relative;
  width: 100%;

  ::v-deep .input-custom input {
    cursor: pointer !important;
  }

  .input-action-icon {
    cursor: pointer;
    position: absolute;
    z-index: 1;
    top: 27px;
    right: 5px;
    width: 32px;
    height: 32px;
    background-color: inherit;
    color: var(--primary-color);
  }
}
</style>
